/***
**Module Name: navlink
 **File Name :  navlink.js
 **Project :    Zeus
 **Copyright(c) : X Platform Consulting.
 **Organization : Peafowl Inc
 **author :  Hari
 **author :  Bhargava
 **author :  Prasanna
 **license :
 **version :  1.0.0 
 **Created on :
 **Created on: Oct 20 2017
 **Last modified on: June 21 2018
 **Description : Menu navigation. 
 ***/
import React from 'react';
import { Link } from 'react-router';
var createReactClass = require('create-react-class');

export default createReactClass({
  render() {
    return <Link {...this.props} activeClassName="active"/>
  }
})