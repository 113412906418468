import React from 'react';

const AlertPopup = ({message, closePopup, title, imagePath, showClose}) => {
    return (
        <div className="alert_block novideo-alert">
            <div className="alert_wrapper">
                <div className="alert_box">
                    {showClose && <span className="popup-close" onClick={closePopup}></span>}
                    <div className="Alert_head">
                        <img alt="asset" src={window.site.config.imagesAssetResourcesPrefix + imagePath} className="error_img" />
                    </div>
                    <div className="Alert_body">
                        <p className="sub">{title}</p>
                        <p>{message}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AlertPopup;