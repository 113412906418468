export const BASE_HREF = "";

// API endpoint for retrieving the attendees list, joining the room, and ending the room
export const CHIME_ROOM_API = "https://uv47r1gg2c.execute-api.us-east-1.amazonaws.com/Prod";

// Chime-SDK allows up to 16 attendee videos
export const CHIME_ROOM_MAX_ATTENDEE = 16;

export const DEFAULT_API_URL = 'https://content-screening-api.dev.envoi.cloud'

export const DEFAULT_ENVOI_MEDIA_UTILITY_API_BASE_URL = 'https://media-utility-api.dev.envoi.cloud';
export const DEFAULT_ENVOI_SCREENING_API_BASE_URL = 'https://content-screening-api.dev.envoi.cloud';

export const DEFAULT_IMAGE_BASE_URL = ''

// The default player type, should be one of: ivs | bitmovin
export const DEFAULT_PLAYER_TYPE = 'envoi';

export const DEFAULT_BITMOVIN_ANALYTICS_KEY = 'bb830415-dfd0-489c-8362-0016331cffed';
// The license key to pass to the player. A player key is required if the playerType = bitmovin
export const DEFAULT_BITMOVIN_PLAYER_KEY = '0e0e35e4-28eb-4180-a7d8-278f5a334a0a';

// Default video stream to play inside the video player
export const DEFAULT_VIDEO_STREAM = "https://fcc3ddae59ed.us-west-2.playback.live-video.net/api/video/v1/us-west-2.893648527354.channel.DmumNckWFTqz.m3u8";

// Default Chat websocket link
export const CHAT_WEBSOCKET = "wss://8n2bcewq39.execute-api.us-east-1.amazonaws.com/Prod";

// Chime-SDK logging level: INFO, WARN, ERROR, DEBUG
export const CHIME_LOG_LEVEL = 'WARN';

// Chime-Web UI debugging logging: true / false
export const DEBUG = false;

