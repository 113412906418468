import React from 'react';
let siteSettings = window.site || {};
let siteConfig = siteSettings.config || {};
let commonResourcesUrl = siteConfig.commonResourcesUrl || 'https://envoi-common-resources.imgix.net/'
let imagesAssetResourcesPrefix = commonResourcesUrl + "screening/";

const AlertPopup = () => (
    <div>
        <div className="novideo-alert" id="ErrorPopup" style={{ display: 'none' }}>
            <div className="alert-u">
                <p className="u-pop-icon"><img src={imagesAssetResourcesPrefix + "images/states_of_applications/warning.png"} alt=""/></p>
                <p>Profile Already Exists!</p>
            </div>
        </div>
        
        <div className="novideo-alert" id="SuccessAddPopup" style={{ display: 'none' }}>
            <div className="alert-u">
                <p className="u-pop-icon"><img src={imagesAssetResourcesPrefix + "images/states_of_applications/success.png"} alt=""/></p>
                <p>Added successfully</p>
            </div>
        </div>
    </div>
)
export default AlertPopup;
